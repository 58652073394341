
























































































































































































import BatteryLevel from "./BatteryLevel.vue";
import TagBadge from "./TagBadge.vue";
import MapWithPoints from "@/components/MapWithPoints.vue";
import CacophonyIndexGraph from "@/components/Audio/CacophonyIndexGraph.vue";
import api from "@/api";
import DeviceLink from "@/components/DeviceLink.vue";
import StationLink from "@/components/StationLink.vue";
import GroupLink from "@/components/GroupLink.vue";
import { RecordingProcessingState } from "@typedefs/api/consts";
import {
  ApiAutomaticTrackTagResponse,
  ApiHumanTrackTagResponse,
} from "@typedefs/api/trackTag";
import { DisplayTag, IntermediateDisplayTag } from "./RecordingsList.vue";
import { Option } from "./LayeredDropdown.vue";
import { getClassifications } from "./ClassificationsDropdown.vue";

import { getDisplayTags, TagClass } from "./Video/AudioRecording.vue";
import { ApiTrackResponse, ApiTrackDataRequest } from "@typedefs/api/track";
import { RecordingType } from "@typedefs/api/consts";

const addToListOfTags = (
  allTags: Record<string, IntermediateDisplayTag>,
  tagName: string,
  isAutomatic: boolean,
  taggerId: number | null
) => {
  const tag = allTags[tagName] || {
    taggerIds: [],
    automatic: false,
    human: false,
  };
  if (taggerId && !tag.taggerIds.includes(taggerId)) {
    tag.taggerIds.push(taggerId);
  }
  if (isAutomatic) {
    tag.automatic = true;
  } else {
    tag.human = true;
  }
  allTags[tagName] = tag;
};

const collateTags = (
  recType: RecordingType,
  options: Option,
  recTags: any[],
  tracks: ApiTrackResponse[]
): DisplayTag[] => {
  // Build a collection of tagItems - one per animal
  const tagItems: Record<string, DisplayTag> = {};
  if (tracks) {
    for (let j = 0; j < tracks.length; j++) {
      const track = tracks[j];
      if (recType === RecordingType.Audio) {
        const displayTags = getDisplayTags(options, track);

        for (let i = 0; i < displayTags.length; i++) {
          const tag = displayTags[i];
          addToListOfTags(
            tagItems,
            tag.what,
            tag.automatic,
            tag.automatic ? null : tag.userId
          );
          if (tag.class === TagClass.Confirmed) {
            tagItems[tag.what].automatic = true;
            tagItems[tag.what].human = true;
          }
        }
        continue;
      }

      // For track tags, pick the best one, which is the "master AI" tag.
      const aiTag = track.tags.find(
        (tag: ApiAutomaticTrackTagResponse) => tag.model === "Master"
      );
      const humanTags = track.tags.filter(
        (tag: ApiHumanTrackTagResponse) => !tag.automatic
      );

      let humansDisagree = false;
      if (aiTag && humanTags.length !== 0) {
        humansDisagree = humanTags.some(
          (tag: ApiHumanTrackTagResponse) => tag.what !== aiTag.what
        );
      }

      if (aiTag && !humansDisagree) {
        addToListOfTags(tagItems, aiTag.what, aiTag.automatic, null);
      }

      // Also add human tags:
      for (const tag of humanTags) {
        addToListOfTags(tagItems, tag.what, tag.automatic, tag.userId);
      }
    }
  }

  // Use automatic and human status to create an ordered array of objects
  // suitable for parsing into coloured spans
  const result = [];
  result.push(...recTags);
  for (let animal of Object.keys(tagItems).sort()) {
    const tagItem = tagItems[animal];
    let subOrder = 0;
    if (animal === "false positive") {
      subOrder = 3;
    } else if (animal === "multiple animals") {
      animal = "multiple";
      subOrder = 2;
    } else if (animal === "unidentified") {
      animal = "?";
      subOrder = 1;
    }

    if (tagItem.automatic && tagItem.human) {
      result.push({
        text: animal,
        class: "automatic human",
        taggerIds: tagItem.taggerIds,
        order: subOrder,
      });
    } else if (tagItem.human) {
      result.push({
        text: animal,
        class: "human",
        taggerIds: tagItem.taggerIds,
        order: 10 + subOrder,
      });
    } else if (tagItem.automatic) {
      result.push({
        text: animal,
        class: "automatic",
        order: 20 + subOrder,
      });
    }
  }
  // Sort the result array
  result.sort((a, b) => {
    return a.order - b.order;
  });
  return result;
};

export default {
  name: "RecordingSummary",
  components: {
    CacophonyIndexGraph,
    GroupLink,
    StationLink,
    DeviceLink,
    MapWithPoints,
    TagBadge,
    BatteryLevel,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    displayStyle: {
      type: String,
      required: true,
      default: "cards",
    },
    futureSearchQuery: {
      type: Object,
    },
  },
  mounted: async function () {
    this.options = (await getClassifications()) as Option;
  },
  data() {
    return {
      showingLocation: false,
      options: {},
    };
  },
  computed: {
    headerClass() {
      if (this.item.filtered || this.item.redacted) {
        return "filtered-recording";
      }
      return "";
    },
    filteredCount() {
      return this.item.tracks.filter((track) => track.filtered).length;
    },
    filteredTags() {
      if (this.$store.state.User.userData.showFiltered) {
        return (
          collateTags(
            this.item.type,
            this.options,
            this.item.recTags,
            this.item.tracks
          ) ?? []
        );
      } else {
        const goodTracks = this.item.tracks.filter((track) => !track.filtered);
        return (
          collateTags(
            this.item.type,
            this.options,
            this.item.recTags,
            goodTracks
          ) ?? []
        );
      }
    },
    thumbnailSrc(): string {
      return api.recording.thumbnail(this.item.id);
    },
    hasBattery() {
      return this.item.batteryLevel;
    },
    window: {
      get() {
        return window;
      },
    },
    queuedForProcessing(): boolean {
      const state = this.item.processingState.toLowerCase();
      return (
        (state === RecordingProcessingState.Analyse ||
          state === RecordingProcessingState.AnalyseThermal ||
          state === RecordingProcessingState.Tracking ||
          state === RecordingProcessingState.Reprocess) &&
        !this.item.processing
      );
    },
    processing(): boolean {
      return this.item.processing;
    },
    corruptedOrFailed(): boolean {
      const state = this.item.processingState;
      return (
        state === RecordingProcessingState.Corrupt ||
        (state as string).endsWith(".failed")
      );
    },
    itemLocation(): { name: string; location: string }[] {
      return [
        {
          name: `${this.item.deviceName}, #${this.item.id}`,
          location: this.item.location,
        },
      ];
    },
  },
  methods: {
    showLocation() {
      this.showingLocation = true;
    },
    async navigateToRecording(event, recordingId) {
      if (event.target !== event.currentTarget && event.target.href) {
        // Clicking a link inside the outer card link
        return;
      }
      if (!(event.metaKey || event.ctrlKey || event.shiftKey)) {
        // Don't change the route if we're ctrl-clicking
        event.preventDefault();
        await this.$router.push({
          path: `/recording/${recordingId}`,
          query: this.futureSearchQuery,
        });
      }
    },
    getRecordingPath(recordingId) {
      return this.$router.resolve({
        path: `/recording/${recordingId}`,
        query: this.futureSearchQuery,
      }).href;
    },
  },
};
