import { render, staticRenderFns } from "./RecordingView.vue?vue&type=template&id=0fc929ee&scoped=true&"
import script from "./RecordingView.vue?vue&type=script&lang=ts&"
export * from "./RecordingView.vue?vue&type=script&lang=ts&"
import style0 from "./RecordingView.vue?vue&type=style&index=0&id=0fc929ee&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fc929ee",
  null
  
)

export default component.exports